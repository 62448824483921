<!--Auth-signup-three component-->
<template>
  <div>
    <div class="back-to-home rounded d-none d-sm-block">
      <router-link to="/" class="btn btn-icon btn-primary">
        <home-icon class="icons"></home-icon>
      </router-link>
    </div>
    <!-- Hero Start -->
    <section class="bg-home  d-flex align-items-center">
      <!--      <div class="bg-overlay bg-overlay-white"></div>-->
      <img src="../assets/tmp/login-bj.jpg" class="bj">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-8">
            <div class="card login_page shadow rounded border-0">
              <div class="card-body">
                <h4 class="card-title text-center">Sign up</h4>
                <form class="login-form mt-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Your Email <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <mail-icon class="fea icon-sm icons"></mail-icon>
                          <input type="email" class="form-control pl-5" placeholder="Email" name="email"
                                 v-model="data.email"/>
                        </div>
                      </div>
                    </div>
<!--         Verification Code -->
<!--                    <div class="col-md-12">-->
<!--                      <div class="form-group">-->
<!--                        <label>Verification Code <span class="text-danger">*</span></label>-->
<!--                        <div class="d-flex">-->
<!--                          <div class="position-relative " style="flex: 1;width: 0">-->
<!--                            <shield-icon class="fea icon-sm icons"></shield-icon>-->
<!--                            <input type="text" class="form-control pl-5" placeholder="Enter Your Verification Code"-->
<!--                                   name="code"-->
<!--                                   v-model="data.code"/>-->
<!--                          </div>-->
<!--                          <button type="button" class="btn btn-primary" style="margin-left: 10px" @click="sendCode" :disabled="disSend"> {{emailCodeCountdownTime >= 60 ? 'Send' : ('（' + emailCodeCountdownTime + 's）Send')}}</button>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->


                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Password <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <key-icon class="fea icon-sm icons"></key-icon>
                          <input type="password" class="form-control pl-5" placeholder="Password"
                                 v-model="data.password"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Re-type Password <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <key-icon class="fea icon-sm icons"></key-icon>
                          <input type="password" class="form-control pl-5" placeholder="Re-type Password"
                                 v-model="data.reTypePassword"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12" v-if="showReferralCode===true">
                      <div class="form-group">
                        <label>Referral Code</label>
                        <div class="position-relative">
                          <user-check-icon class="fea icon-sm icons"></user-check-icon>
                          <b-input disabled type="text" class="pl-5" placeholder="Referral Code"
                                   v-model="data.referralCode"/>
                        </div>
                      </div>
                    </div>
<!--                    <div class="col-md-12">-->
<!--                      <div class="form-group ">-->
<!--                        <label>WhatsApp (Optional)</label>-->
<!--                        <div class="position-relative">-->
<!--                          <smartphone-icon class="fea icon-sm icons"></smartphone-icon>-->
<!--                          <b-input type="text" class="pl-5" placeholder="WhatsApp (Optional)"-->
<!--                                   v-model="data.whatsapp"/>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="col-lg-12" style="margin-bottom: 10px;" v-if="alert.show">
                      <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>
                    </div>
                    <div class="col-md-12">
                      <div @click="register" class="btn btn-primary btn-block">
                        Register
                      </div>
                    </div>

                    <div class="mx-auto">
                      <p class="mb-0 mt-3">
                        <small class="text-dark mr-2">Already have an account ?</small>
                        <router-link to="/auth-login" class="text-dark font-weight-bold">Sign in</router-link>
                      </p>
                    </div>
                    <div>
                      <hr>
                      <small class="text-dark mr-2">By creating an account, I agree to IncCrypto <router-link to="/terms" class="text-dark font-weight-bold">Privacy Policy and Terms</router-link></small>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->
<!--    <Switcher/>-->
    <b-modal id="modal-no-backdrop" title="Confirmation" size="sm" button-size="sm" ok-variant="success" hide-footer
             hide-backdrop content-class="shadow" v-model="showSignupText">
      <p class="my-2" style="white-space: pre-line;font-weight: bold">
        {{ this.change_msg(this.signup_text) }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import Switcher from "@/components/switcher";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  HomeIcon,
  UserIcon,
  UserCheckIcon,
  MailIcon,
  KeyIcon,
  ShieldIcon,
  SmartphoneIcon,
} from "vue-feather-icons";
import accountApi from '../apis/accountApi';
import {Loading} from "element-ui";
import storage from "@/storage";

export default {
  metaInfo: {
    title: 'IncCrypto: The Best Cloud Mining Platform and Service Provider for Cloud Mining Technology Services',
    link: [
      { rel: 'canonical', href: 'https://inccrypto.com/auth-signup' }
    ]
  },
  data() {
    return {
      data: {
        email: "",
        password: "",
        reTypePassword: "",
        referralCode: "",
        code: "",
        whatsapp: '',
        source: '',
      },
      showReferralCode: false,
      disSend: false,
      emailCodeCountdownTime: 60,
      emailCodeCountdownInter: 0,
      alert: {
        show: false,
        text: ""
      },
      showSignupText: false,
      signup_text: 'Registration is successful!\nPlease activate your account through the email link.',
    };
  },
  components: {
    Switcher,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    HomeIcon,
    UserIcon,
    UserCheckIcon,
    MailIcon,
    KeyIcon,
    ShieldIcon,
    SmartphoneIcon
  },
  created() {
    this.fetchRouteParams();
  },
  mounted() {
    // this.data.referralCode = this.$route.query.ref
    this.data.referralCode = new URLSearchParams(window.location.search).get('ref');
    if(this.data.referralCode && this.data.referralCode.length>0){
      console.log('referral code:'+this.data.referralCode)
      this.showReferralCode = true
    }
    this.data.source = sessionStorage.getItem('source') || ''
    console.log("source:"+this.data.source)
    if(this.data.source === '' || this.data.source === null){
      // const currentURL = window.location.href;
      // this.data.source = new URL(currentURL).origin;
      this.data.source = window.location.hostname
    }
    if (this.$route.query.code === 0) {
      this.data.email = this.$route.query.email
      this.$message({
        showClose: true,
        message: 'Verification code expired.',
        type: 'error',
        duration: 0
      });
    }
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId == 'modal-no-backdrop') {
        this.$router.push("/auth-login")
      }
    })
  },
  watch: {
    showSignupText(val) {
      if (val != this.showSignupText & val == false) {
        this.$router.push("/auth-login")
      }
    }
  },
  destroyed() {
    this.clearEmailCodeCountdown()
  },
  methods: {
    fetchRouteParams() {
      const urlParams = new URLSearchParams(window.location.search);
      const utmCampaign = urlParams.get('utm_campaign');
      const utmSource = urlParams.get('utm_source');
      // console.log('utm_campaign:', utmCampaign);
      if(utmCampaign === 'coinband'){
        // console.log('utm_source:', utmSource)
        if(utmSource === 'google'){
          sessionStorage.setItem('source', 'cb-google');
        }
        if(utmSource === 'facebook'){
          sessionStorage.setItem('source', 'cb-facebook');
        }
      }else if(utmSource === 'taboola') {
        sessionStorage.setItem('source', 'taboola');
      }
    },
    change_msg(msg) {
      msg.toString().replace(/\\n/g, ' ')
      return msg
    },
    register() {
      const emailRegex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      // const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-])[\w~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-]{8,}$/;
      const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[\w~!@#$%^&*()_+|[\]{};:'",<.>/?`=|-]{8,}$/i;

      //判断是否可以进行登录操作
      if (this.data.email == "") {
        this.alert.show = true
        this.alert.text = "Email cannot be empty."
      } else if (this.data.password == "") {
        this.alert.show = true
        this.alert.text = "Password cannot be empty."
      } else if (this.data.reTypePassword == "") {
        this.alert.show = true
        this.alert.text = "Re-type Password cannot be empty."
      } else if (this.data.reTypePassword !== this.data.password) {
        this.alert.show = true
        this.alert.text = "The passwords you have entered do not match."
      } else if (!emailRegex.test(this.data.email)) {
        this.alert.show = true
        this.alert.text = "Invalid email."
      } else if (!passwordRegex.test(this.data.password)) {
        this.alert.show = true
        this.alert.text = "Password must be at least 8 characters long and contain at least one letter and one digit."
      }
      // else if (this.data.code == "") {
      //   this.alert.show = true
      //   //验证码不得为空
      //   this.alert.text = "Verification code cannot be empty."
      // }
      else {
        const loading = Loading.service({
          lock: true,
          text: 'Sign up...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let data = JSON.parse(JSON.stringify(this.data))
        data.password = btoa(this.data.password)
        accountApi.register(data, res => {
          loading.close()
          if (res.code != 200) {
            this.alert.show = true
            this.alert.text = res.msg
          } else {
            //储存返回的用户id
            storage.localSet({ userId: res.data })
            this.$router.push("/profile/overview")
            // window.location.href="https://stavlin.shop"
            // this.signup_text = res.msg
            // this.showSignupText = !this.showSignupText;

            // sessionStorage.removeItem('source')
            // this.$router.push("/auth-login")
          }
        })
      }
    },
    sendCode(){
      if (this.data.email == "") {
        this.alert.show = true
        //邮箱地址不得为空
        this.alert.text = "Your email cannot be empty"
        return
      }
      let data = {
        email: this.data.email
      }
      const loading = Loading.service({
        lock: true,
        text: 'Send code...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      accountApi.getEmailCode(data, res => {
        loading.close()
        if (res.code != 200) {
          this.alert.show = true
          this.alert.text = res.msg
        } else {
          this.alert.show = false
          this.alert.text = ''
          this.getEmailCodeCountdown()
        }
      })
    },
    getEmailCodeCountdown() {
      console.log(this.emailCodeCountdownTime)
      this.disSend = true
      if (this.emailCodeCountdownTime <= 0) {
        this.clearEmailCodeCountdown()
        return
      }
      this.emailCodeCountdownInter = setInterval(() => {
        if (this.emailCodeCountdownTime <= 0) {
          this.clearEmailCodeCountdown()
          console.log('stop')
          return
        } else {
          this.emailCodeCountdownTime --
        }
      }, 1000)
    },
    clearEmailCodeCountdown(){
      clearInterval(this.emailCodeCountdownInter)
      this.emailCodeCountdownTime = 60
      this.disSend = false
    }
  }
};
</script>


<style scoped lang="scss">
.bg-home{
  //background-image: url("../assets/log");
  position: relative;
  background-color: rgba(250, 251, 254, 1);

  .bj{
    position: absolute;
    width: 54%;
    height: 100vh;
    object-fit: fill;
    top: 0;
    left: 0;
  }

  hr {
    border-top: 1px solid bisque;
  }

}
</style>

